<template>
  <v-app>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-12 order-xl-1">
        <!-- <v-btn
          text
          @click="addCoupon"
          outlined
          class="btn btn-lg btn-add-main mb-2"
          style="background:transparent;"
        >Add Coupon</v-btn>-->
        <KTPortlet v-bind:title="'Order Reports'">
          <template v-slot:toolbar></template>
          <template v-slot:body>
            <div class="kt-invoice__body">
              <div class="row" v-if="loading">
                <div class="col-md-12 mt-2">
                  <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
                </div>
              </div>
              <div class="row" v-else-if="!loading && data && data.length<1">
                <div class="col-md-12 text-center">
                  <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                  <h5 class="mt-10">Looks like you have not added any products yet. Add some products to get report.</h5>
                </div>
              </div>

              <div class="kt-invoice__body mt-5" v-else>
                <v-row class="mb-2">
                  <v-col cols="3">
                    <v-text-field dense outlined v-model="filter.title" label="Product Title"></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field dense outlined v-model="filter.price" label="Product Price"></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-select
                      dense
                      outlined
                      v-model="filter.status"
                      label="Status"
                      :items="productStatuses"
                      item-text="name"
                      item-value="value"
                    ></v-select>
                  </v-col>
                  <!-- <v-col cols="2">
                    <v-switch v-model="filter.warning_quantiy" label="< warning Quantity"></v-switch>
                  </v-col> -->
                  <v-col cols="3">
                    <v-select
                      :items="['paypal', 'stripe', 'khalti', 'esewa', 'cash']"
                      dense
                      outlined
                      v-model="filter.category"
                      label="Payment Method"
                    ></v-select>
                  </v-col>

                  <v-col cols="2">
                    <v-btn color="primary" :loading="isBusy" outlined @click="filterProdcut">Filter</v-btn>
                  </v-col>
                </v-row>
                <b-table
                  hover
                  :items="data"
                  id="my-table"
                  :busy.sync="isBusy"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template v-slot:cell(is_active)="row">
                    <span>
                      <i
                        class="fas"
                        :class="row.item.is_active?'fa-check text-success':'fa-ban text-danger'"
                      ></i>
                    </span>
                  </template>
                  <template v-slot:cell(is_sale)="row">
                    <span>
                      <i
                        class="fas"
                        :class="row.item.is_sale?'fa-check text-success':'fa-ban text-danger'"
                      ></i>
                    </span>
                  </template>
                  <template v-slot:cell(is_featured)="row">
                    <span>
                      <i
                        class="fas"
                        :class="row.item.is_featured?'fa-check text-success':'fa-ban text-danger'"
                      ></i>
                    </span>
                  </template>
                  <template v-slot:cell(inventory_stock)="row">
                    <span
                      class="badge badge-primary"
                      v-if="row.item.inventory_stock"
                    >{{row.item.inventory_stock}}</span>
                    <span v-else>
                      <i class="fas fa-ban text-danger"></i>
                    </span>
                  </template>
                  <template v-slot:cell(warning_qty)="row">
                    <span
                      class="badge badge-primary"
                      v-if="row.item.warning_qty"
                    >{{row.item.warning_qty}}</span>
                    <span v-else>
                      <i class="fas fa-ban text-danger"></i>
                    </span>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ProductService from "@/services/store/ProductService";

const productService = new ProductService();
export default {
  components: {
    KTPortlet
  },

  data() {
    return {
      loading: true,
      filter: {},
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      coupons: [],
      websites: [],
      data: [],
      fields: [
        { key: "title", sortable: true },
        { key: "price", sortable: true },
        { key: "is_active", label: 'Status', sortable: true },
        { key: "is_sale", label: 'On Sale', sortable: true },
        { key: "is_featured", label: 'Featured', sortable: true },
        { key: "inventory_stock", sortable: true },
        { key: "warning_qty", label: 'Notification on Low Stock', sortable: true }
        // { key: "no_of_sell", sortable: true }
      ],
      productStatuses: [
        { value: "active", name: "Active" },
        { value: "in-active", name: "In-active" },
        { value: "featured", name: "Featured" },
        { value: "sale", name: "In Sale" },
        { value: "best_seller", name: "Best Seller" },
        { value: "most_viewed", name: "Most Viewed" },
        { value: "recently_ordered", name: "Recently Ordered" }
      ]
    };
  },
  computed: {
    formTitle() {
      return this.edit ? "Update Coupon Code" : "Create Coupon Code";
    }
  },
  watch: {
    $route() {
      this.filter.status = this.$route.params.status;
      this.filterProdcut();
    }
  },
  mounted() {
    // console.log(this.$route.params.status)
     this.filter.status = this.$route.params.status;
      this.filterProdcut();
  },
  methods: {
    getDefaultDate() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = "0" + month;
      let day = date.getDate() - 7;
      if (day.toString().length == 1) day = "0" + day;
      this.dates.push(year + "-" + month + "-" + day);
    },
    filterProdcut() {
      this.isBusy = true;
      productService
        .productReport(this.filter)
        .then(response => {
          this.data = response.data.result;
        })
        .catch(error => {
          // console.log(error);
        })
        .finally(() => {
          this.loading = false;
          this.isBusy = false;
        });
    }
  }
};
</script>
